<template>
  <el-row>
    <el-container style="height:calc(93.5vh);">
      <el-header class="header">
        <span class="left el-icon-arrow-left" @click="returnOn"></span>
        <div class="search">
          <el-input placeholder="输入手机号搜索预约信息" prefix-icon="el-icon-search" v-model="searchVal">
          </el-input>
          <el-button type="primary">搜索</el-button>
        </div>
      </el-header>
      <el-container style="height:calc(93.5vh);">
        <el-container>
          <el-main class="main">
            <div v-if="true" class="list-box">
              <div class="list">
                <div class="list-doc">
                  <div class="doc-t">
                    <img src="@/assets/images/avatar.png" alt="">
                    <div class="name">顾小敏</div>
                    <div class="pro">美容师</div>
                  </div>
                  <div class="doc-b">
                    <i class="el-icon-time"></i>2020-01-03 09:00-10:15
                  </div>
                </div>
                <div class="list-info">
                  <div class="info-t">
                    <el-button size="mini">客户信息</el-button>
                  </div>
                  <div class="info-b">赵晓梅 15858585858</div>
                </div>
                <div class="list-pro">
                  <div class="pro-t">
                    <el-button size="mini">预约项目</el-button>
                  </div>
                  <div class="pro-b">头皮平衡补水精华、美白祛斑体验、资深美容专家面诊</div>
                </div>
                <div class="list-btn">
                  <el-button type="primary" size="mini" @click="lookOrderDetail('已关闭')">查看详情<i
                      class="el-icon-arrow-right"></i>
                  </el-button>
                </div>
              </div>
              <div class="list list1">
                <div class="list-doc">
                  <div class="doc-t">
                    <img src="@/assets/images/avatar.png" alt="">
                    <div class="name">顾小敏</div>
                    <div class="pro">美容师</div>
                  </div>
                  <div class="doc-b">
                    <i class="el-icon-time"></i>2020-01-03 09:00-10:15
                  </div>
                </div>
                <div class="list-info">
                  <div class="info-t">
                    <el-button size="mini">客户信息</el-button>
                  </div>
                  <div class="info-b">赵晓梅 15858585858</div>
                </div>
                <div class="list-pro">
                  <div class="pro-t">
                    <el-button size="mini">预约项目</el-button>
                  </div>
                  <div class="pro-b">头皮平衡补水精华、美白祛斑体验、资深美容专家面诊</div>
                </div>
                <div class="list-btn">
                  <el-button type="primary" size="mini" @click="lookOrderDetail('服务中')">查看详情<i
                      class="el-icon-arrow-right"></i>
                  </el-button>
                </div>
              </div>
              <div class="list list2">
                <div class="list-doc">
                  <div class="doc-t">
                    <img src="@/assets/images/avatar.png" alt="">
                    <div class="name">顾小敏</div>
                    <div class="pro">美容师</div>
                  </div>
                  <div class="doc-b">
                    <i class="el-icon-time"></i>2020-01-03 09:00-10:15
                  </div>
                </div>
                <div class="list-info">
                  <div class="info-t">
                    <el-button size="mini">客户信息</el-button>
                  </div>
                  <div class="info-b">赵晓梅 15858585858</div>
                </div>
                <div class="list-pro">
                  <div class="pro-t">
                    <el-button size="mini">预约项目</el-button>
                  </div>
                  <div class="pro-b">头皮平衡补水精华、美白祛斑体验、资深美容专家面诊</div>
                </div>
                <div class="list-btn">
                  <el-button type="primary" size="mini" @click="lookOrderDetail('已预约')">查看详情<i
                      class="el-icon-arrow-right"></i>
                  </el-button>
                </div>
              </div>
            </div>
            <div v-else class="box">
              <img src="@/assets/images/null.png" alt="">
              <div>
                <el-button type="primary" icon="el-icon-plus" @click="jumpAddAbout">添加预约</el-button>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </el-row>

</template>

<script>
  export default {
    data() {
      return {
        searchVal: '',
        tabsChange: '2',
        timePicker: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
      }
    },
    created() {
      this.searchVal = this.$route.params.searchVal
    },
    methods: {
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      jumpAddAbout() {
        this.$router.push({
          name: 'AddReservation'
        })
      }
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }

    .search {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .el-button--primary {
        height: 35px;
        margin-left: -6px;
        z-index: 1;
        font-size: 12px;
        border-radius: 0 5px 5px 0;

      }

      .el-button--primary:focus,
      .el-button--primary:hover,
      .el-button:active,
      .el-button:focus,
      .el-button:hover {
        color: #fff;
        border-color: #409eff;
        background-color: #409eff;
      }

      /deep/ .el-input__inner {
        height: 35px;
        border: 1px solid #dcdfe6;
        border-right: 0;
        border-radius: 35px 0 0 35px;

        &:focus,
        &:hover {
          border-color: #dcdfe6;
        }
      }

      .el-button {
        border-radius: 0 35px 35px 0;

      }
    }

  }



  .main.el-main {
    padding: 0 0px !important;

    .list-box {
      padding: 10px;
      box-sizing: border-box;
      background-color: #f9f9f9;
      min-height: calc(100vh - 80px);

      .list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        border-left: 4px solid #24d2d3;
        color: #666;
        font-size: 12px;
        background-color: #fff;
        margin-bottom: 10px;

        .list-doc {
          width: 25%;

          .doc-t {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;

            img {
              width: 35px;
              height: 35px;
            }

            .name {
              margin: 0 8px;
            }

            .pro {
              color: #999;
            }
          }
        }

        .list-info {
          width: 20%;
        }

        .list-pro {
          width: 35%;

          .pro-b {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .info-t,
        .pro-t {
          margin-bottom: 18px;

          /deep/.el-button:focus,
          .el-button:hover {
            color: #666 !important;
            border-color: #dcdfe6 !important;
            background-color: #ffffff !important;
          }
        }

        .list-btn {
          width: 20%;
          text-align: center;

          /deep/.el-button:focus,
          .el-button:hover {
            color: #fff !important;
            border-color: #409eff !important;
            background-color: #409eff !important;
          }
        }
      }

      .list1 {
        border-left: 4px solid #ffa94c
      }

      .list2 {
        border-left: 4px solid #51d351;
      }
    }

    .box {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      img {
        width: 240px;
        margin-bottom: 20px;
      }
    }
  }

</style>
